/* Three columns side by side */
.team-section-left {
    float: left;
    width: 31.3%;
    margin-bottom: 16px;
    padding: 0 8px;
  }
  .m-tb-50{
    margin: 50px 0px;
  }
 
  
  /* Add some shadows to create a card effect */
  .team-section-left .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Some left and right padding inside the teams */
  .team-section-left .teams {
    padding: 0 16px;
  }
  
  
  .team-section-left .button {
    border: none;
    outline: 0;
    box-sizing: border-box;
    display: inline;
    padding: 10px;
    color: #ffffff;
    background-color: #104f86;
    text-align: center;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    margin: 6px 0px;

  }
  
.team-section-left .button:hover {
    background-color: #0056b3;
  }
.teams-img{
   width: 100%;
   text-align: center;
}
.team-subtitle{
  font-size: 15px;
}
   /* Display the columns below each other instead of side by side on small screens */
   @media screen and (max-width: 650px) {
    .team-section-left {
      width: 100%;
      display: block;
    }
    .team-section-left {
        padding: 0 0px;
    }
  }