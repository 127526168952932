body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bgcolor-white {
  background-color: #ffffff;
}

.bgcolor-black {
  background-color: #000000;
}

.bgcolor-blue {
  background-color: #104f86;
  padding: 10px;
}

.common-width {
  width: 90%;
  margin: 0 auto;
}

.color-white {
  color: #ffffff;
}

.color-black {
  color: #000000;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
}

.subtitle {
  font-size: 17px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.common-m-tb {
  margin: 40px 0px;
}

.common-m-t {
  margin-top: 140px;
}

.w-100 {
  width: 100%;
}

.slick-next {
  right: 0px !important;
}

@media(max-width:1023px) {
  .text-justify {
    text-align: left;
  }

  .subtitle {
    font-size: 15px;
  }
  .title {
    font-size: 15px;
  }
  
}