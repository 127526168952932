.header-top{
    margin: 0px;
    padding: 0px;
    background-color: #000000;
}
.header-top li{
    display: inline-block;
    padding: 10px;
    margin: 10px 6px;
    text-decoration: none;
}
.header-top li a{
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 15px;
}
#logo-size{
    height: 80px;
    width: 100px;
}
#logo{
    margin-top: 10px;
    padding: 0px;
    width: 26%;
}
.heght-fixed{
    position: relative;
    top: -35px;
}
.display-block{
    display: block;
}
.display-none{
    display: none;
}
.display-property{
    display: flex;
    width: 100%;
    justify-content: space-between
}
.logo-size{
    height: 75px;
    width: 90px;
}
.float-right{
    float: right;
    margin-top: 60px !important;
}
.height-100{
    height: 100px;
}
.logo-title{
    color: #269f6d;
    vertical-align: top;
    margin-top: 23px;
    display: inline-block;
    margin-left: 10px;
    font-size: 17px;
}
.menu-hide-show{
    display: none;
}
.menu-show :hover.menu-hide-show{
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #000000;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 12px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  /* Add a grey background color to dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #000000;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
@media(max-width:1185px){
    .logo-title {
        margin-top: 29px;
        margin-left: 9px;
        font-size: 16px;
    }
    .header-top li a {
       font-size: 12px;
    }
}
@media(max-width:1100px){
    .logo-title {
        margin-top: 26px;
        margin-left: 9px;
        font-size: 16px;
    }
}


@media(max-width:1030px){
    .logo-title {
        margin-top: 26px;
        margin-left: 13px;
        font-size: 18px;
    }
    .header-top li a {
        font-size: 12px;
    }
}
@media(max-width:1088px){
    .header-top li{
        display: block;
        margin-top: 0px;
        padding-bottom: 5px;
        text-align: center;
       
    }
    .display-block{
        display: none;
    }
    .display-none{
        display: block;
        background-color: #000000;
    }
    #logo-size {
        height: 60px;
        width: 80px;
    } 
    .header-top li a {
        font-size: 13px;
    }
    .menubar-color{
        margin-top: 20px !important;
    }
    .display-property {
        margin: 10px 0px;
    }
    .display-property {
        justify-content: space-around;
    }
    .dropdown-content {
        right: 7px;
        top: 0px;
    }
}

@media(max-width:719px){
    .heght-fixed{
        position: relative;
        top: 0px;
    }
    .header-top li a {
        font-size: 15px;
    }
    
}