
.galley-left{
    float: left;
    width: 30%;
    margin: 10px;
    border: 1px solid #000000;
    box-sizing: border-box;
}
.gallery-img{
    width: 100%;
}
.gallery-img img{
    width: 100%;
}

@media(max-width:1023px){
    .galley-left{   
        float: none;
        width: 100%;
        margin: 10px 0px;
    }  
}