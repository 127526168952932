.register-container {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .common-width-login{
    width: 50%;
    margin: 0 auto;
  }
   .login-form{
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
   }
  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .login-form input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .login-form textarea{
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .login-form input:focus {
    border-color: #104f86;
  }
  
  .login-form small.error {
    display: block;
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .login-form button.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #104f86;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .login-form button.submit-button:hover {
    background-color: #104f86;
  }
  .p-t-b-100{
    padding: 100px 0px;
  }

  .login-form button.submit-button[disabled] {
    background-color: #d6d6d6;
  }
  
  .login-form button.submit-button:hover:not([disabled]) {
    background-color: #0056b3;
  }
  
  .login-form .alert {
    padding: 10px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .login-form .alert.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .login-form .alert.error {
    background-color: #f8d7da;
    color: #721c24;
  }

  @media(max-width:1023px){
    .common-width-login {
        width: 100%;
    }
    .login-form button.submit-button {
        font-size: 15px;
    }
  }
  