/* General styles */
body {
    margin: 0;
    padding: 0;
  }
  
  .client-app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .client-navbar {
    background-color: #333;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .navbar-logo img {
    height: 40px;
  }
  
  .navbar-links a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
  }
  
  .client-main-content{
    display: flex;
    flex: 1;
  }
  
  .client-sidebar {
    width: 250px;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .client-dashboard {
    flex: 1;
    padding: 20px;
  }
  
  