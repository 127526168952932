.home-page{
    padding-top: 300px;
}
.home-m-t{
    margin-top: 90px;
}
.text-absolute{
    position: absolute;
    z-index: 1;
    top:50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    color: #ffffff;

}
.pb-20{
    padding-bottom: 40px;
}
.m-t-b{
    margin: 20px 0px;
}
@media(max-width:1100px){
    .text-absolute {
        font-size: 26px;
    }
}
@media(max-width:1023px){
    .home-m-t {
        margin-top: 50px;
    }
    .text-absolute{
        top: unset;
    }
    .text-absolute {
        left: 55px;
        right: unset;
        transform: unset;
        font-size: 17px;
        top: 100px;
        text-align: center;
    }
}

@media(max-width:719px){
    .text-absolute {
        font-size: 13px;
    }
}

@media(max-width:419px){
    .home-m-t {
        margin-top: 78px;
    }
}