
.pd{
   padding: 0px;
}
.clearfixed:after {
    clear: both;
    content: "";
    display: table;
}
.about-float {
    box-sizing: border-box;
    float: left;
    margin: 10px;
    padding: 12px;
    width: 42%;
}
.about-border, .about-float {
    border: 1px solid #fff;
}
.about-img{
    width: 100%;
    padding: 4px;
}
.about-img img{
    width: 100%;
}
.about-subtitle {
    color: #ffffff;
    font-size: 17px;
    text-align: left;
    margin: 0px;
}
.about-client{
    padding: 30px;
}

@media(max-width:1023px){
    .about-float{
        float: none;
        width: 100%;
        margin: 10px 0px;
    }
    .about-subtitle {
        font-size: 16px;
    }
}