/* src/components/TopBar.css */
.topbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  align-items: center;
}

.topbar-left h2 {
  margin: 0;
}

.menu-toggle {
  font-size: 24px;
  display: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.topbar-right .user-info {
  display: flex;
  align-items: center;
}

.topbar-right .user-info span {
  margin-right: 10px;
}

.logout-btn {
  padding: 5px 10px;
  background-color: #104f86;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
}
