.footer-text{
    text-align: center;
   
}
.footer-bottom {
    margin: 0px;
    padding: 0px;
    background-color: #000000;
}
.footer-bottom li {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
}
.footer-bottom li a {
    text-decoration: none;
    color: #ffffff;
    text-transform: capitalize;
}
.fa {
    padding: 10px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
  }
  
  .fa:hover {
      opacity: 0.7;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
  
  .fa-twitter {
    background: #55ACEE;
    color: white;
  }
  
  .fa-google {
    background: #dd4b39;
    color: white;
  }
  
  .fa-linkedin {
    background: #007bb5;
    color: white;
  }
  
  .fa-youtube {
    background: #bb0000;
    color: white;
  }
  
  .fa-instagram {
    background: #125688;
    color: white;
  }
@media(max-width:479px){
    .footer-bottom li {
        margin: 0px;
        font-size: 13px;
    }
}