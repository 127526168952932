/* src/App.css */
.dashboard-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dashboard-body {
  display: flex;
  flex: 1;
}

.dashboard-content {
  flex: 1;
  padding: 20px;
  margin-left: 250px;
  transition: margin-left 0.3s ease;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 0;
  }
}
