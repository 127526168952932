/* src/components/MainContent.css */
.main-content {
  margin-left: 270px;
  padding: 20px;
  transition: margin-left 0.3s;
}

.card-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.card-admin {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 30%;
  text-align: center;
}

.card-admin h3 {
  margin-bottom: 10px;
}

.card-admin p {
  font-size: 24px;
  font-weight: bold;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }

  .card-container {
    flex-direction: column;
    gap: 10px;
  }

  .card-admin {
    width: 100%;
  }
}
