/* src/components/Sidebar.css */
.sidebar {
    width: 220px;
    background-color: #f4f4f4;
    padding: 20px;
    height: 100vh;
    position: fixed;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 20px 0;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
  }
  
  .sidebar ul li a:hover {
    color: #007bff;
  }
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      left: -100%;
      display: none;
    }
  
    .sidebar.open {
      left: 0;
      display: block;
    }
  }
  