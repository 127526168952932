
.float-left-img {
    float: left;
    width: 33.3%;
    cursor: pointer;
}
.img-width{
    padding: 10px;
}
.img-width img{
    width: 100%;
    -webkit-filter: blur(2px);
     filter: blur(1px);
}
.img-title{
    position: relative;
    border: 1px solid #000000;;
    
}

.img-title:hover{
   transition-property: width;
   transition-timing-function: ease-in-out;
}
.img-subtitle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    background-color: #ffffff;
    width: 100%;
    font-weight: 800;
    text-align: center;
}
.clearfixed::after{
    content: "";
    clear: both;
    display: table;
}
.page-title{
    text-align: center;
    padding-top: 20px;
    color: #ffffff;
    text-transform: uppercase;
}
.apaar-subtitle{
    font-size: 15px;
    padding: 10px;

}
/* animation */

 
  
/* animation */
 .fade-img {
    transition: transform 0.3s ease-in-out;
}

.fade-img:hover {
    transform: scale(1.1);
}
.process-img{
  text-align: center;
  margin: 10px;
}

@media(max-width:1023px){
    .float-left-img {
        float: left;
        width: 100%;
        cursor: pointer;
    }
}

@media(max-width:719px){
    .img-subtitle {
        font-size: 20px;
    }
}